<template>
  <div class="w-100">
    <!-- <span v-for="(section, index) in sections" :key="section" :ref="section" :id="section" class="w-100">
      <component :is="getComponent(section)" :isBackgroundPrimary="index % 2 === 0"
        @scrollToSection="scrollToSection" />
    </span> -->

    <FormSection :isBackgroundPrimary="true" specialty="dentist"></FormSection>
  </div>
</template>

<script>
import HeroSection from './sections/HeroSection.vue';
import AdvantagesSection from './sections/AdvantagesSection.vue';
import TestimonialsSection from './sections/TestimonialsSection.vue';
import FaqSection from './sections/FaqSection.vue';
import SpecialtiesSection from './sections/SpecialtiesSection.vue';
import FormSection from '../specialties/components/FormSection.vue';


export default {
  components: {
    HeroSection,
    AdvantagesSection,
    TestimonialsSection,
    SpecialtiesSection,
    FaqSection,
    FormSection,
  },
  data() {
    return {
      sections: ['hero', 'advantages', 'testimonials', 'specialties', 'faq'],
      currentSectionIndex: 0,
      isScrolling: false, // To prevent rapid scrolling
    };
  },
  methods: {
    getComponent(section) {
      const components = {
        hero: 'HeroSection',
        advantages: 'AdvantagesSection',
        testimonials: 'TestimonialsSection',
        specialties: 'SpecialtiesSection',
        faq: 'FaqSection',
      };
      return components[section];
    },
    scrollToSection(sectionId) {
      const element = this.$refs[sectionId][0];
      if (element) {
        const yOffset = 0; // Adjust the offset if you have a fixed header
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },
    handleScroll(event) {
      if (this.isScrolling) return; // Prevent multiple triggers
      this.isScrolling = true;
      setTimeout(() => {
        this.isScrolling = false;
      }, 500); // Adjust the delay as needed

      const currentSection = this.$refs[this.sections[this.currentSectionIndex]][0];
      const sectionBottom = currentSection.getBoundingClientRect().bottom;
      const sectionTop = currentSection.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight;

      if (event.deltaY > 0 && sectionBottom <= viewportHeight + 50) {
        // Scroll down
        this.scrollToNextSection();
      } else if (event.deltaY < 0 && sectionTop >= -50) {
        // Scroll up
        this.scrollToPreviousSection();
      }
    },
    scrollToNextSection() {
      if (this.currentSectionIndex < this.sections.length - 1) {
        this.currentSectionIndex++;
        this.scrollToSection(this.sections[this.currentSectionIndex]);
      }
    },
    scrollToPreviousSection() {
      if (this.currentSectionIndex > 0) {
        this.currentSectionIndex--;
        this.scrollToSection(this.sections[this.currentSectionIndex]);
      }
    },
  },
  mounted() {
    // this.sections.forEach(section => {
    //   const observer = new IntersectionObserver(entries => {
    //     entries.forEach(entry => {
    //       if (entry.isIntersecting) {
    //         this.currentSectionIndex = this.sections.indexOf(entry.target.id);
    //       }
    //     });
    //   }, {
    //     threshold: 1.0, // Adjust the threshold as needed
    //   });
    //   observer.observe(this.$refs[section][0]);
    // });

    // window.addEventListener('wheel', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.handleScroll);
  },
};
</script>

<style scoped>
/* Apply styles that are common across sections, if needed */
html {
  scroll-behavior: smooth;
}

/* Ensure consistent section styling */
.section {
  padding: 5rem 1rem;
  scroll-snap-align: start;
}

@media (max-width: 768px) {
  .section {
    padding: 3rem 1rem;
  }
}

@media (max-width: 576px) {
  .section {
    padding: 2rem 1rem;
  }
}
</style>
