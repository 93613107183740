<template>
    <section ref="advantages" id="advantages" :class="[
        'advantages-section',
        isBackgroundPrimary ? 'bg-gradient-primary text-white' : '',
        'py-5 min-vh-100 rounded'
    ]">
        <div class="container text-center d-flex flex-column justify-content-center h-100">
            <h1 :class="[
                isBackgroundPrimary ? 'text-white' : 'text-primary',
            ]">
                Pourquoi AquaMedico?
            </h1>
            <b-row class="mt-4">
                <b-col md="4" v-for="advantage in advantages" :key="advantage.title">
                    <b-card class="text-center">
                        <b-img :src="require(`@/assets/images/pages/${advantage.image}`)" :alt="advantage.alt"
                            class="mb-3"></b-img>
                        <h3>{{ advantage.title }}</h3>
                        <b-card-text>{{ advantage.text }}</b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-button variant="primary mx-auto" :to="{name: 'aqua-form'}">
                <span class="text-light">Demander votre compte</span>
            </b-button>
        </div>

    </section>
</template>

<script>
import { BCard, BImg, BRow, BCol, BCardText, BButton } from 'bootstrap-vue'

export default {
    name: 'AdvantagesSection',
    components: {
        BCard,
        BCardText,
        BImg,
        BRow,
        BCol,
        BButton,
    },
    props: {
        isBackgroundPrimary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            advantages: [
                { title: 'Automatisation', image: 'calendar-illustration.png', alt: 'Icon 1', text: '3H et plus à gagner chaque jour grâce à l\'automatisation de CNSS, CNOPS, FAR…' },
                { title: 'Statistiques', image: 'forgot-password.png', alt: 'Icon 2', text: 'Des statistiques sur les actes les plus effectués du mois, le chiffre d\'affaire, l\'impayé…' },
                { title: 'Rentabilité', image: 'calendar-illustration.png', alt: 'Icon 3', text: 'Augmentation de base clientèle grâce au prise de RDV en ligne.' }
            ]
        }
    }
}
</script>

<style scoped>
.min-vh-100 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.advantages-section .b-card {
    margin-top: 20px;
}

/* Responsive font size */
.advantages-section h1 {
    font-size: 2.5rem;
    /* Adjust as needed */
}

/* Responsive design for various screen sizes */
@media (max-width: 768px) {
    .advantages-section h1 {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .advantages-section h1 {
        font-size: 1.5rem;
    }
}
</style>